<template>
    <ModalTemplate
        v-model="showModal"
        name="timezone-selector"
		queryName="m_tzs"
		:closer="closer">
        <div class="timezone__selector">
            <div class="header">
				<div class="close__button" @click="closeHandler">
					<span class="iconify"  data-width="24" data-height="24" data-icon="uil:arrow-left"></span>
				</div>
                <div class="header__title">
					Select timezone
				</div>
            </div>
            <b-input placeholder="Search"
                v-model="searchString"
                icon="magnify"
                :icon-right="searchString.length > 0 ? 'close-circle' : ''"
                icon-right-clickable
                @icon-right-click="searchString = ''">
            </b-input>
            <div class="searched_timezones_cont" v-if="searchedTimezone.length > 0">
                <div class="timezone" 
                    v-for="tz,index in getTimezones(searchString)"
                    :key="`timezone__${index}`"
                    @click="() => updateTimezone(tz)">
                        {{tz.name}}
                    </div>
            </div>
            <div class=" no_items" v-else>
                Timezone is undefined
            </div>
        </div>
    </ModalTemplate>
</template>

<script>
import ModalProvider from '../../../scripts/modalProvider';
import ModalTemplate from '../../modals/ModalTemplate.vue';

export default {
    name: 'TimezoneSelector',
	components: {
		ModalTemplate
	},
    props:{
        timezone: {
            type: Object,
        },  
        show: {
            type: Boolean,
            default: false,
            required: true
        },
        closer: {
            type: Function,
            default: () => {},
            required: true
        }
    },
    model: {
        prop: 'timezone',
        event: 'update:timezone'
    },
    computed: {
        showModal:{
            get(){
                return this.show
            },
            set(){}
        },
        searchedTimezone(){
            return this.getTimezones(this.searchString)
        },
        timezones(){
            return this.$store.getters.timezones
        }
    },
    data() {
        return {

            searchString: "",
            currentTimezone: this.$attrs.timezone
        };
    },
    methods: {
        getTimezones(searchString){
            return Object.values(this.timezones).filter(tz => {
                let lowerSearch = searchString.toLowerCase()
                let lowerName = tz.name.toLowerCase()
                return lowerName.includes(lowerSearch)
            })
        },
        updateTimezone(tz){
            this.$emit('update:timezone', tz)
            this.closeHandler()
        },
        updateSearchString(e){

            let inputMode = e.inputType == "insertText"

            if (inputMode){
                this.searchString += e.data
            }
        },
		closeHandler(){
			ModalProvider.back()
		}
    },
};
</script>

<style scoped>
.search__cont{
    margin: 0 20px;
    position: relative;
    margin-bottom: 10px;
}
.header{
	position: relative;
}

.timezone__selector{
	background: white;
	bottom: 66px;
	position: absolute;
	right: 20px;
	padding: 16px;
	border-radius: 8px;
	left: 20px;
	top: 40px;

	display: flex;
	flex-direction: column;
	gap: 10px;
}
.header__title {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;	
}

.close__button{
    position: absolute;
	height: 24px;
	top: 3px;
}
.searched_timezones_cont{
    height: 100%;
    width: 100%;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
}

.timezone + .timezone{
    border-top: 1px solid #dbdbdb;
}

.timezone{
    width: 100%;
    min-height: 42px;
    font-size: 14px;
    padding: 11px 16px;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
input {
    width: inherit;
    padding: 6px 6px 7px 6px;
    font-size: 16px;
    border-radius: 2px;
    border: 1px solid rgb(68, 68, 68);
}

.no_items{
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

input:focus{
    outline: none;
}

.search__field {
    border-radius: 2px;
    border: 1px solid rgb(68, 68, 68);
    display: grid;
    grid-template-columns: calc(100% - 16px - 37px) 37px;
    grid-column-gap: 16px;
}

.clear__button{
    display: flex;
    justify-content: center;
    align-items: center;
}

.search__field > input {
    border: none;
}
</style>